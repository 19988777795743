<template>
    <div class="wrap-dashboard">
        <div class="top-menu">
            <div class="filter">
                <div class="sort-date">
                    <!-- <dropdown>
                <template slot="toggler">
                  <button @click="toggle" class="btn-filter">Sort by Date: {{ form.startDate + " - " + form.endDate }}</button>
                </template>
                <dropdown-content class="dropdown">
                  <input
                    placeholder="Pilih tanggal awal"
                    type="text"
                    onfocus="(this.type='date')"
                    onblur="(this.type='text')"
                    id="start-date"
                    :max="today"
                    class="form-input"
                    v-model="form.startDate"
                  />
                  <input
                    placeholder="Pilih tanggal akhir"
                    type="text"
                    onfocus="(this.type='date')"
                    onblur="(this.type='text')"
                    id="start-date"
                    class="form-input"
                    v-model="form.endDate"
                    :min="form.startDate"
                    :max="today"
                    :disabled="form.startDate == ''"
                  />
                  <input type="submit" value="Apply" class="btn-primer" @click="filterDate" />
                </dropdown-content>
              </dropdown> -->
                    <dropdown-directive position="left" tryProps="">
                        <template v-slot:buttonDropdown>
                            <button class="btn-filter">Sort by Date: {{ form.startDate + " - " + form.endDate }}</button>
                        </template>
                        <template v-slot:menuDropdown>
                            <div class="filter-dropdown">
                                <input
                                    placeholder="Choose Start Date"
                                    type="text"
                                    onfocus="(this.type='date')"
                                    onblur="(this.type='text')"
                                    id="start-date"
                                    :max="today"
                                    class="form-input"
                                    v-model="form.startDate"
                                />
                                <input
                                    placeholder="Choose End Date"
                                    type="text"
                                    onfocus="(this.type='date')"
                                    onblur="(this.type='text')"
                                    id="end-date"
                                    class="form-input"
                                    v-model="form.endDate"
                                    :min="form.startDate"
                                    :max="today"
                                    :disabled="form.startDate == ''"
                                />
                                <div class="wrap-button">
                                    <button type="button" class="btn-primer" @click="filterDate">Apply</button>
                                </div>
                            </div>
                        </template>
                    </dropdown-directive>
                </div>
                <div class="sort-pialang">
                    <select name="" id="" class="filter-select" v-model="brokerCode" @click="filterBroker">
                        <option value="" selected disabled>Broker</option>
                        <option value="001">001 - ABI</option>
                    </select>
                </div>
                <div class="sort-bank">
                    <select name="" id="" v-model="bankCode" class="filter-select" @change="filterBank">
                        <option value="" selected disabled>Bank</option>
                        <option value="">All</option>
                        <option value="037">BAGI</option>
                        <option value="022">CIMB</option>
                    </select>
                </div>
                <div class="sort-bank">
                    <select name="" id="" v-model="credit" class="filter-select" @change="filterType">
                        <option value="" selected disabled>Type</option>
                        <option value="">All</option>
                        <option value="credit">Deposit</option>
                        <option value="debit">Withdrawal</option>
                    </select>
                </div>
                <div class="reset-filter">
                    <button type="button" @click="resetFilter" v-show="form.endDate != ''">Reset Filter</button>
                </div>
            </div>
            <div class="wrap-excel">
                <ExportExcelInOut v-show="hideExcel" ref="exportExcelId" @setLoadDownload="setIsloadingDownload" />
                <dropdown-directive>
                    <template v-slot:buttonDropdown>
                        <button class="btn-filter">Download</button>
                    </template>
                    <template v-slot:menuDropdown>
                        <div class="filter-dropdown">
                            <div class="select-type-date">
                                <div class="wrap-input">
                                    <input
                                        type="radio"
                                        name=""
                                        id="By Month"
                                        value="month"
                                        v-model="typeDate"
                                        @change="(formExcel.startDate = ''), (formExcel.endDate = ''), (formExcel.monthDate = '')"
                                    />
                                    <label for="By Month">By Month</label>
                                </div>
                                <div class="wrap-input">
                                    <input
                                        type="radio"
                                        name=""
                                        id="By Date"
                                        value="date"
                                        v-model="typeDate"
                                        @change="(formExcel.startDate = ''), (formExcel.endDate = ''), (formExcel.monthDate = '')"
                                    />
                                    <label for="By date">By Date</label>
                                </div>
                            </div>
                            <div v-if="typeDate == 'date'">
                                <div>
                                    <label for="start-date">Start</label>
                                    <input
                                        placeholder="Pilih tanggal awal excel"
                                        type="date"
                                        id="start-date"
                                        :max="today"
                                        class="form-input"
                                        v-model="formExcel.startDate"
                                        @change="changeMaxDate"
                                    />
                                </div>
                                <div>
                                    <label for="end-date">End</label>
                                    <input
                                        placeholder="Pilih tanggal akhir"
                                        type="date"
                                        id="end-date"
                                        class="form-input"
                                        v-model="formExcel.endDate"
                                        :min="formExcel.startDate"
                                        :max="maxTodayDownload"
                                    />
                                </div>
                            </div>
                            <div v-else>
                                <div>
                                    <label for="">Month</label>
                                    <input type="month" name="" id="" class="form-input" v-model="formExcel.monthDate" :max="todayMonth" />
                                </div>
                            </div>
                            <button type="button" class="btn-primer" @click="tableToExcel" :disabled="formExcel.monthDate == '' && formExcel.endDate == ''">
                                <b-spinner variant="light" v-if="isLoading" small></b-spinner>
                                <span v-else>Apply</span>
                            </button>
                        </div>
                    </template>
                </dropdown-directive>
            </div>
        </div>
        <div class="content">
            <div class="view-perpages">
                <select v-model="perPage" @change="showDataRow">
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
                <span>&nbsp;entries per page</span>
            </div>
            <table>
                <thead>
                    <tr id="top">
                        <th scope="col">No</th>
                        <th scope="col">Timestamp</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Type</th>
                        <th scope="col">Description</th>
                        <th scope="col">RECEIVER</th>
                    </tr>
                </thead>
                <tbody v-if="loadingData">
                    <tr>
                        <td class="text-center" colspan="6">
                            <b-spinner label="Loading..."></b-spinner>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr v-for="(data, i) in showData" :key="i" v-show="showData.length != 0">
                        <td>{{ (currentPage - 1) * perPage + (i + 1) }}</td>
                        <td>{{ data.created_at | formatDateTimeReal }}</td>
                        <td>{{ formatIDR(data.amount) }}</td>
                        <td>{{ data.type == "credit" ? "Deposite" : "Withdrawal" }}</td>
                        <td>
                            <!-- {{ data.newDescription[0] + "-" + data.newDescription[1] + "-" + data.newDescription[2] }} -->
                            <span v-for="(dataDesc, j) in data.newDescription" :key="j">
                                {{ dataDesc + " " + (j != data.newDescription.length - 1 ? "-" : " ") }}
                            </span>
                        </td>
                        <td>
                            <img src="@/assets/bagi.png" alt="" class="icon-trade" v-show="data.info.bank == '037'" />

                            {{ " - " + data.newDescription[2] }}
                        </td>
                    </tr>
                    <tr v-show="showData.length == 0">
                        <td class="text-center" colspan="6">No Data Available</td>
                    </tr>
                </tbody>
            </table>
            <div class="d-flex justify-content-end mt-3">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="lengthData"
                    :per-page="perPage"
                    @change="handlePageChange"
                    aria-controls="new-table"
                    first-number
                    last-number
                    v-show="lengthData > 10"
                ></b-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import ExportExcelInOut from "../../components/ExportExcelInOut.vue";
// import * as XLSX from "xlsx";
// import Dropdown from "../components/dropdown/Dropdown.vue";
// import DropdownContent from "../components/dropdown/DropdownContent.vue";
import DropdownDirective from "../../components/dropdown/DropdownDirective.vue";
export default {
    components: {
        ExportExcelInOut,
        // Dropdown,
        // DropdownContent,
        DropdownDirective,
    },
    data() {
        return {
            typeDate: "month",
            currentPage: 1,
            perPage: 10,
            offset: 0,
            data: [],
            loadingData: false,
            hideExcel: false,
            today: "",
            todayMonth: "",
            isLoading: false,
            form: {
                startDate: "",
                endDate: "",
            },
            formExcel: {
                startDate: "",
                endDate: "",
                monthDate: "",
            },
            revenue: "",
            imgRevenue: null,
            role: sessionStorage.getItem("role"),
            displayCategory: false,
            selectedId: null,
            showToggle: false,
            description: [],
            bankCode: "037",
            brokerCode: "001",
            toggleExcel: false,
            type: "",
            credit: "",
            statusWithdrawal: "success",
            maxTodayDownload: "",
        };
    },
    mounted() {
        this.today = new Date().toISOString().split("T")[0];
        this.todayMonth = this.today.slice(0, 7);
        this.role = sessionStorage.getItem("role");
        if (this.role == 2) {
            this.revenue = "abx";
            this.imgRevenue = require("@/assets/abx.png");
        } else if (this.role == 13) {
            this.revenue = "Bank";
            this.imgRevenue = require("@/assets/agi.png");
        } else {
            this.revenue = "kbi";
            this.imgRevenue = require("@/assets/logo-kbi.png");
        }

        this.loadData(this.perPage, this.currentPage, this.form.startDate, this.form.endDate, this.bankCode, this.credit, this.statusWithdrawal);

        let x = document.getElementsByClassName("idr");
        for (let i = 0, len = x.length; i < len; i++) {
            let num = Number(x[i].innerHTML).toLocaleString("id");
            x[i].innerHTML = num;
            x[i].classList.add("currSign");
        }
    },
    methods: {
        setIsloadingDownload(event) {
            this.isLoading = event;
        },
        changeMaxDate() {
            let today = new Date().toISOString().split("T")[0];
            if (this.formExcel.startDate.slice(0, 7) === today.slice(0, 7)) {
                this.maxTodayDownload = today;
            } else {
                const date = new Date(this.formExcel.startDate);
                const month = date.getMonth();
                const year = date.getFullYear();
                const maxDate = new Date(year, month + 1, 0).getDate();
                this.maxTodayDownload = this.formExcel.startDate.slice(0, 7) + "-" + JSON.stringify(maxDate);
            }

            this.formExcel.endDate = "";
        },
        inputToExcel() {
            var data = {
                limit: this.perPage,
                offset: 0,
                startDate: this.formExcel.startDate,
                endDate: this.formExcel.endDate,
                bank_code: this.bankCode,
                credit: this.credit,
                status: this.statusWithdrawal,
            };
            this.$emit("inputExcel", data);
            this.$root.$emit("inputExcel", data);
        },
        btnTableEcxel() {
            this.formExcel.startDate = "";
            this.formExcel.endDate = "";
            this.toggleExcel = !this.toggleExcel;
        },
        tableToExcel() {
            if (this.formExcel.monthDate != "") {
                const date = new Date(this.formExcel.monthDate);
                const month = date.getMonth();
                const year = date.getFullYear();
                const maxDate = new Date(year, month + 1, 0).getDate();
                this.formExcel.startDate = this.formExcel.monthDate + "-01";
                this.formExcel.endDate = this.formExcel.monthDate + "-" + JSON.stringify(maxDate);
            }

            const dataParamsExcel = {
                bank_code: this.bankCode,
                credit: this.credit,
                status: this.statusWithdrawal,
                startDate: this.formExcel.startDate,
                endDate: this.formExcel.endDate,
                limit: this.perPage,
                offset: 0,
            };
            this.$refs.exportExcelId.setDataDownload(dataParamsExcel);
            // const table = document.getElementById("tableCashInOut");
            // const wb = XLSX.utils.table_to_book(table);
            // var wscols = [{ wch: 5 }, { wch: 20 }, { wch: 15 }, { wch: 10 }, { wch: 40 }, { wch: 15 }];
            // wb["Sheets"]["Sheet1"]["!cols"] = wscols;
            // let filename = "Data_Cash_Withdrawal_ " + this.formExcel.startDate + " - " + this.formExcel.endDate + ".xlsx";
            // XLSX.writeFile(wb, filename, { cellText: true });
        },
        toggle() {
            this.showToggle = !this.showToggle;
        },
        handlePageChange(value) {
            this.loadData(this.perPage, value, this.form.startDate, this.form.endDate, this.bankCode, this.credit, this.statusWithdrawal);

            window.scrollTo({ top: 0, behavior: "smooth" });
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2);
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        formatIDR(value) {
            let val = (value / 1).toFixed(2).replace(".", ",");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        filterDate() {
            this.loadData(this.perPage, this.currentPage, this.form.startDate, this.form.endDate, this.bankCode, this.credit, this.statusWithdrawal);

            this.showToggle = !this.showToggle;
        },
        filterType() {
            this.loadData(this.perPage, this.currentPage, this.form.startDate, this.form.endDate, this.bankCode, this.credit, this.statusWithdrawal);
        },
        filterBroker() {},
        filterBank() {
            this.loadData(this.perPage, this.currentPage, this.form.startDate, this.form.endDate, this.bankCode, this.credit, this.statusWithdrawal);
        },
        showDataRow() {
            this.loadData(this.perPage, this.currentPage, this.form.startDate, this.form.endDate, this.bankCode, this.credit, this.statusWithdrawal);
        },
        resetFilter() {
            this.form.startDate = "";
            this.form.endDate = "";
            this.type = "";
            this.bankCode = "";
            this.loadData(this.perPage, this.currentPage, this.form.startDate, this.form.endDate, this.bankCode, this.credit, this.statusWithdrawal);
        },

        loadData(perPage, currentPage, startDate, endDate, bank_code, credit, status) {
            this.loadingData = true;

            this.$store
                .dispatch("loadCashDepWith", {
                    perPage: perPage,
                    currentPage: currentPage,
                    startDate: startDate,
                    endDate: endDate,
                    bank_code: bank_code,
                    credit: credit,
                    status: status,
                })
                .then(() => {
                    this.loadingData = false;
                });
        },
        select(itemId) {
            this.selectedId = itemId;
            this.displayCategory = !this.displayCategory;
        },
        selectedDropdown(value, img) {
            this.revenue = value;
            this.imgRevenue = img;
        },
    },
    computed: {
        rows() {
            return (this.currentPage - 1) * this.perPage;
        },
        showData() {
            return this.$store.state.cashModule.dataShow;
        },

        lengthData() {
            return this.$store.state.cashModule.lengthData;
        },
        splitDescription() {
            let newData = [...this.data];
            newData.map((data) => {
                return (data.description = data.description.split("-"));
            });
            return newData;
        },
    },
};
</script>

<style scoped>
/*.wrap-dashboard {
  min-height: 100vh;
  margin: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
}*/

.top-title {
    display: flex;
    justify-content: space-between;
}

.bagi {
    height: 30px;
}

.reset-filter button {
    border: none;
    color: #fff;
    background: #f75676;
    padding: 5px 10px;
    font-size: 13px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    cursor: pointer;
}

/* .sort-date {
  display: flex;
  gap: 1rem;
} */

.form-input {
    border: solid 1px #ccc;
    height: 31px;
    padding: 5px 10px;
    border-radius: 5px;
    color: #aaa;
    font-size: 12px;
    width: 100%;
}

.filter-dropdown {
    padding: 1rem;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.btn-primer:hover,
.btn-filter:hover {
    box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
    transform: translateY(-1px);
}

.filter-select {
    border: none;
    background: #fff;
    color: var(--scondary-color);
    padding: 5px;
    font-size: 13px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}

.filter-select:focus {
    outline: none;
}

.btn-filter {
    border: none;
    background: #fff;
    color: var(--scondary-color);
    padding: 5px 20px;
    font-size: 13px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}

/* .bagi-menu {
  background-image: url("../assets/logo_agi.png");
} */

select#revenue option[value="bank"] {
    background-image: url("../../assets/logo_agi.png");
}

.title-page {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 40px;
}

.option-table {
    background: #f8f8f8;
    border: none;
    width: 100%;
}

/* .wrap-content {
  width: 98%;
  height: 100%;
  margin: 0 auto;
  padding: 20px 25px;
} */

.icon-trade {
    width: 25px;
}

.footer {
    background: var(--primary-color);
    color: white;
    padding: 20px 47px;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    height: 62px;
}

.top-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
    background: #fff;
    box-shadow: 0px 3px 5px 1px rgb(0 0 0 / 5%);
    padding: 25px 20px;
    border-radius: 5px;
    position: relative;
}

.dropdown {
    left: 0;
    background: #fff;
    padding: 10px 20px;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    border-radius: 5px;
}

.dropdown-excel {
    background: #fff;
    padding: 10px 20px;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    border-radius: 5px;
    right: 18px;
    position: absolute;
    top: 4rem;
}

.filter {
    display: flex;
    gap: 1rem;
    align-items: center;
    font-size: 12px;
    flex-wrap: wrap;
    position: relative;
    width: 51%;
}

.wrap-input {
    margin-bottom: 10px;
}
.label-input {
    width: 108px;
}
select {
    width: 130px;
    border-radius: 3px;
    border-color: #a5a5a5a5;
    padding: 3px;
}

.view-perpages select {
    width: unset;
}

.card {
    width: 314px;
    font-size: 14px;
    border-radius: 10px;
}

.card-header {
    padding: 1rem;
}

.buyer,
.seller,
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 12px;
    position: relative;
    left: 20px;
}

.title::before {
    content: "";
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: var(--scondary-color);
    left: -17px;
    top: 3px;
}

.buyer {
    margin-bottom: 20px;
}

.content-right {
    text-align: right;
}

.amount {
    font-weight: bold;
}

.btn-primer {
    background: var(--scondary-color);
    border: none;
    color: #ffffff;
    padding: 5px 25px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 13px;
}

.btn-primer:hover {
    background-color: var(--scondary-color-hover);
}

.dropdown-toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdown-menu {
    width: 100%;
    font-size: 13px;
    transform: translate3d(-30px, 21px, 0px);
}

.dropdown-menu.show {
    transform: translate3d(-30px, 21px, 0px) !important;
}

.dropdown-item {
    cursor: pointer;
    display: flex;
    gap: 0.5rem;
    padding: 0.25rem 0.7rem;
}

.img-revenue {
    height: 15px;
}

.wrap-img {
    width: 33px;
    text-align: center;
}

.wrap-img img {
    height: 15px;
}
</style>
