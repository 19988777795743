<template>
    <div class="wrap-dashboard">
        <div class="main-content">
            <div class="wrap-content">
                <div class="content">
                    <table id="tableCashInOut">
                        <thead>
                            <tr id="top">
                                <th scope="col">No</th>
                                <th scope="col">Timestamp</th>
                                <th scope="col">Ammount</th>
                                <th scope="col">Type</th>
                                <th scope="col">Description</th>
                                <th scope="col">Receiver</th>
                            </tr>
                        </thead>
                        <tbody v-if="loadingData">
                            <tr>
                                <td class="text-center" colspan="6">
                                    <b-spinner label="Loading..."></b-spinner>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr v-for="(data, i) in dataInOut" :key="i" v-show="dataInOut.length != 0">
                                <td>{{ (currentPage - 1) * perPage + (i + 1) }}</td>
                                <td>{{ data.created_at | formatDateTimeReal }}</td>
                                <td>{{ formatIDR(data.amount) }}</td>
                                <td>{{ data.type == "credit" ? "Deposite" : "Withdrawal" }}</td>
                                <td>
                                    <span v-for="(dataDesc, j) in data.newDescription" :key="j">
                                        {{ dataDesc + " " + (j != data.newDescription.length - 1 ? "-" : " ") }}
                                    </span>
                                </td>
                                <td>
                                    <!-- <img src="../assets/bagi.png" alt="" class="icon-trade" v-show="data.bank_code == '037'" /> -->

                                    {{ (data.info.bank == "037" ? "BAGI" : "CIMB") + " - " + data.newDescription[2] }}
                                </td>
                            </tr>
                            <tr v-show="dataInOut.length == 0">
                                <td class="text-center" colspan="6">No Data Available</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import * as XLSX from "xlsx";
export default {
    components: {},
    data() {
        return {
            dataTransaction: [],
            pageOfItems: [],
            currentPage: 1,
            perPage: 10,
            lengthData: 1,
            trade_id: [],
            offset: 0,
            dataInOut: [],
            loadingData: false,
            today: "",
            customer: [],
            form: {
                startDate: "",
                endDate: "",
            },
            revenue: "",
            imgRevenue: null,
            role: "",
            displayCategory: false,
            selectedId: null,
            valueDropdwon: "KBI",
            showToggle: false,
            description: [],
            bankCode: "037",
            brokerCode: "001",
            items: [
                {
                    id: 1,
                    name: "Basketball",
                },
                {
                    id: 2,
                    name: "Soccerr",
                },
            ],
        };
    },
    mounted() {
        this.today = new Date().toISOString().split("T")[0];
        this.role = sessionStorage.getItem("role");
        if (this.role == 2) {
            this.revenue = "abx";
            this.imgRevenue = require("@/assets/abx.png");
        } else if (this.role == 13) {
            this.revenue = "Bank";
            this.imgRevenue = require("@/assets/agi.png");
        } else {
            this.revenue = "kbi";
            this.imgRevenue = require("@/assets/logo-kbi.png");
        }
        // this.getData(this.currentPage, this.form.startDate, this.form.endDate, this.bankCode);

        let x = document.getElementsByClassName("idr");
        for (let i = 0, len = x.length; i < len; i++) {
            let num = Number(x[i].innerHTML).toLocaleString("id");
            x[i].innerHTML = num;
            x[i].classList.add("currSign");
        }
    },
    created() {
        this.$root.$on("inputExcel", (data) => {
            const role = sessionStorage.getItem("role");
            let url;
            if (role == 1) {
                url =
                    "/v2/clearing/admin/get/cash_flow?data_type=excel&bank_code=" +
                    data.bank_code +
                    "&type=" +
                    data.credit +
                    "&status=" +
                    data.status +
                    "&startDate=" +
                    data.startDate +
                    "&endDate=" +
                    data.endDate +
                    "&limit=" +
                    data.limit +
                    "&offset=" +
                    data.offset;
            } else if (role == 2) {
                url =
                    "/v2/clearing/admin/abx/get/cash_flow?data_type=excel&?bank_code=" +
                    data.bank_code +
                    "&type=" +
                    data.credit +
                    "&status=" +
                    data.status +
                    "&startDate=" +
                    data.startDate +
                    "&endDate=" +
                    data.endDate +
                    "&limit=" +
                    data.limit +
                    "&offset=" +
                    data.offset;
            } else if (role == 4) {
                url =
                    "/v2/clearing/admin/bank/get/cash_flow?data_type=excel&?bank_code=" +
                    data.bank_code +
                    "&type=" +
                    data.credit +
                    "&status=" +
                    data.status +
                    "&startDate=" +
                    data.startDate +
                    "&endDate=" +
                    data.endDate +
                    "&limit=" +
                    data.limit +
                    "&offset=" +
                    data.offset;
            }
            axios.get(url).then((res) => {
                this.dataInOut = res.data.data.body;
                for (let i = 0; i < this.dataInOut.length; i++) {
                    let newDescription;
                    newDescription = this.dataInOut[i].desc;
                    if (newDescription.includes("-")) {
                        this.dataInOut[i].newDescription = newDescription.split("-");
                    } else {
                        this.dataInOut[i].newDescription = newDescription.split(":");
                    }
                }
            });
        });
    },
    methods: {
        setDataDownload(data) {
            this.$emit("setLoadDownload", true);
            const role = sessionStorage.getItem("role");
            let url;
            if (role == 1) {
                url =
                    "/v2/clearing/admin/get/cash_flow?data_type=excel&bank_code=" +
                    data.bank_code +
                    "&type=" +
                    data.credit +
                    "&status=" +
                    data.status +
                    "&startDate=" +
                    data.startDate +
                    "&endDate=" +
                    data.endDate +
                    "&limit=" +
                    data.limit +
                    "&offset=" +
                    data.offset;
            } else if (role == 2) {
                url =
                    "/v2/clearing/admin/abx/get/cash_flow?data_type=excel&?bank_code=" +
                    data.bank_code +
                    "&type=" +
                    data.credit +
                    "&status=" +
                    data.status +
                    "&startDate=" +
                    data.startDate +
                    "&endDate=" +
                    data.endDate +
                    "&limit=" +
                    data.limit +
                    "&offset=" +
                    data.offset;
            } else if (role == 4) {
                url =
                    "/v2/clearing/admin/bank/get/cash_flow?data_type=excel&?bank_code=" +
                    data.bank_code +
                    "&type=" +
                    data.credit +
                    "&status=" +
                    data.status +
                    "&startDate=" +
                    data.startDate +
                    "&endDate=" +
                    data.endDate +
                    "&limit=" +
                    data.limit +
                    "&offset=" +
                    data.offset;
            }
            axios.get(url).then((res) => {
                this.dataInOut = res.data.data.body;
                for (let i = 0; i < this.dataInOut.length; i++) {
                    let newDescription;
                    newDescription = this.dataInOut[i].desc;
                    if (newDescription.includes("-")) {
                        this.dataInOut[i].newDescription = newDescription.split("-");
                    } else {
                        this.dataInOut[i].newDescription = newDescription.split(":");
                    }
                }
                setTimeout(() => {
                    this.$emit("setLoadDownload", false);
                    this.setToDawnload(data.startDate, data.endDate);
                }, 5000);
            });
        },
        setToDawnload(startDate, endDate) {
            const table = document.getElementById("tableCashInOut");
            const wb = XLSX.utils.table_to_book(table);
            var wscols = [{ wch: 5 }, { wch: 20 }, { wch: 15 }, { wch: 10 }, { wch: 40 }, { wch: 15 }];
            wb["Sheets"]["Sheet1"]["!cols"] = wscols;
            let filename = "Data_Cash_Withdrawal_ " + startDate + " - " + endDate + ".xlsx";
            XLSX.writeFile(wb, filename, { cellText: true });
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2);
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        formatIDR(value) {
            let val = (value / 1).toFixed(2).replace(".", ",");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },

        select(itemId) {
            this.selectedId = itemId;
            this.displayCategory = !this.displayCategory;
        },
        selectedDropdown(value, img) {
            this.revenue = value;
            this.imgRevenue = img;
        },
    },
    computed: {
        rows() {
            return (this.currentPage - 1) * this.perPage;
        },
        splitDescription() {
            let newData = [...this.dataInOut];
            newData.map((data) => {
                return (data.description = data.description.split("-"));
            });
            return newData;
        },
    },
};
</script>

<style scoped>
/*.wrap-dashboard {
  min-height: 100vh;
  margin: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
}*/

.top-title {
    display: flex;
    justify-content: space-between;
}

.bagi {
    height: 30px;
}

.reset-filter button {
    border: none;
    color: #fff;
    background: #f75676;
    padding: 5px 10px;
    font-size: 13px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    cursor: pointer;
}

/* .sort-date {
  display: flex;
  gap: 1rem;
} */

.form-input {
    border: solid 1px #ccc;
    height: 31px;
    padding: 5px 10px;
    border-radius: 5px;
    color: #aaa;
    font-size: 12px;
    margin-right: 10px;
}

.btn-primer:hover,
.btn-filter:hover {
    box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
    transform: translateY(-1px);
}

.filter-select {
    border: none;
    background: #fff;
    color: var(--scondary-color);
    padding: 5px;
    font-size: 13px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}

.btn-filter {
    border: none;
    background: #fff;
    color: var(--scondary-color);
    padding: 5px 20px;
    font-size: 13px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}

/* .bagi-menu {
  background-image: url("../assets/logo_agi.png");
} */

select#revenue option[value="bank"] {
    background-image: url("../assets/logo_agi.png");
}

.title-page {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 40px;
}

.option-table {
    background: #f8f8f8;
    border: none;
    width: 100%;
}

.wrap-content {
    width: 98%;
    height: 100%;
    margin: 0 auto;
    padding: 20px 25px;
}

.content {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
}

.icon-trade {
    width: 25px;
}

.footer {
    background: var(--primary-color);
    color: white;
    padding: 20px 47px;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    height: 62px;
}

.top-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
    background: #fff;
    box-shadow: 0px 5px 16px 1px rgb(0 0 0 / 15%);
    padding: 25px 20px;
    border-radius: 5px;
    position: relative;
}

.dropdown {
    position: absolute;
    right: 0;
    top: 35px;
    background: #fff;
    padding: 10px 20px;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    border-radius: 5px;
}

.filter {
    display: flex;
    gap: 1rem;
    align-items: center;
    font-size: 12px;
    flex-wrap: wrap;
    position: relative;
    width: 48%;
}

.wrap-input {
    margin-bottom: 10px;
}
.label-input {
    width: 108px;
}
select {
    width: 130px;
    border-radius: 3px;
    border-color: #a5a5a5a5;
    padding: 3px;
}

.card {
    width: 314px;
    font-size: 14px;
    border-radius: 10px;
}

.card-header {
    padding: 1rem;
}

.buyer,
.seller,
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 12px;
    position: relative;
    left: 20px;
}

.title::before {
    content: "";
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: var(--scondary-color);
    left: -17px;
    top: 3px;
}

.buyer {
    margin-bottom: 20px;
}

.content-right {
    text-align: right;
}

.amount {
    font-weight: bold;
}

.btn-primer {
    background: var(--scondary-color);
    border: none;
    color: #ffffff;
    padding: 5px 25px;
    border-radius: 5px;
    cursor: pointer;
}

.btn-primer:hover {
    background-color: var(--scondary-color-hover);
}

.dropdown-toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdown-menu {
    width: 100%;
    font-size: 13px;
    transform: translate3d(-30px, 21px, 0px);
}

.dropdown-menu.show {
    transform: translate3d(-30px, 21px, 0px) !important;
}

.dropdown-item {
    cursor: pointer;
    display: flex;
    gap: 0.5rem;
    padding: 0.25rem 0.7rem;
}

.img-revenue {
    height: 15px;
}

.wrap-img {
    width: 33px;
    text-align: center;
}

.wrap-img img {
    height: 15px;
}
</style>
